import { useNavigate, useParams } from "react-router-dom";
import { AppFormBar } from "../../components/common/AppFormBar";
import { Form } from "antd";
import "./CooperationAddPage.css";
import "react-quill/dist/quill.snow.css";
import { useState } from "react";
import { ProjectForm } from "../../components/project/ProjectForm";
import { CreateProjectInput, StatusEnum, useProjectQuery } from "../../graphql";

export const ProjectUpdatePage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  /***Input */

  /***Input */

  const [form] = Form.useForm<CreateProjectInput>();
  const [status, setStatus] = useState(StatusEnum.Published);

  const { data: projectData } = useProjectQuery({
    variables: {
      id: Number(id),
    },
    skip: !id,
    fetchPolicy: "network-only",
    onError(error) {
      console.log("FIND ERROR", error);
      // navigate("/admin/projects");
    },
  });

  const submit = (status: StatusEnum) => {
    setStatus(status);
    form.submit(); // Trigger errors
  };

  return (
    <>
      <AppFormBar
        onCancel={() => navigate("/admin/projects")}
        title={
          projectData?.project?.id
            ? `[${projectData?.project?.title}]`
            : "Modifier le projet"
        }
        onValidate={submit}
      />

      <ProjectForm form={form} project={projectData?.project} status={status} />
    </>
  );
};
