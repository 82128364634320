import { useNavigate, useParams } from "react-router-dom";
import { AppFormBar } from "../../../components/common/AppFormBar";
import { Form } from "antd";
import "../CooperationAddPage.css";
import { useState } from "react";
import { BourseForm } from "../../../components/bourse/BourseForm";
import {
  CreateBourseInput,
  StatusEnum,
  useBourseQuery,
} from "../../../graphql";

export const BourseUpdatePage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  /***Input */

  /***Input */

  const [form] = Form.useForm<CreateBourseInput>();
  const [status, setStatus] = useState(StatusEnum.Published);

  const { data: bourseData } = useBourseQuery({
    variables: {
      id: Number(id),
    },
    fetchPolicy: "network-only",
    skip: !id,
    onError(error) {
      console.log("FIND ERROR", error);
      navigate("/admin/offers");
    },
  });

  const submit = (status: StatusEnum) => {
    setStatus(status);
    form.submit(); // Trigger errors
  };
  return (
    <>
      <AppFormBar
        onCancel={() => navigate("/admin/scholarships")}
        title={
          bourseData?.bourse?.id
            ? `[${bourseData?.bourse?.title}]`
            : "Modifier la bourse"
        }
        onValidate={submit}
      />

      <BourseForm form={form} bourse={bourseData?.bourse} status={status} />
    </>
  );
};
