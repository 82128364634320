import { Card, Col, Form, Input, Row, Select, notification } from "antd";
import { FormInstance } from "antd/es/form";
import ReactQuill from "react-quill";
import { quillModules, quillFormats } from "../../constants/quill-configs";
import { AppImageInput } from "../common/AppMedia";
import {
  CreateProjectInput,
  DictionaryTypeEnum,
  ProjectDataFragment,
  StatusEnum,
  useDictionariesQuery,
  useProjectCreateMutation,
  useProjectUpdateMutation,
} from "../../graphql";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getStatusMasculinMessage } from "../../utils/status-message";
import { useNavigate } from "react-router-dom";
import { filTerDictionary, filterOption } from "../../utils/build-options";

type Props = {
  form: FormInstance<CreateProjectInput>;
  project: ProjectDataFragment | null | undefined;
  status: StatusEnum;
};

export const ProjectForm = ({ form, project, status }: Props) => {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();

  /**Cooperations types */
  const { data: dictionaryData } = useDictionariesQuery({
    variables: {
      types: [DictionaryTypeEnum.ProjectType],
    },
  });

  const types = useMemo(
    () =>
      filTerDictionary(
        DictionaryTypeEnum.ProjectType,
        dictionaryData?.dictionaries || []
      ),
    [dictionaryData]
  );

  const [logos, setLogos] = useState<number[]>([]);

  const [createOffter, { loading: loadingCreate }] = useProjectCreateMutation({
    onCompleted: () => {
      api.success({
        message: "Nouveau projet",
        description: `Projet crée et ${getStatusMasculinMessage(
          status
        )} avec succès !`,
      });
      setTimeout(() => {
        navigate("/admin/projects");
      }, 500);
    },
    onError: (error) => {
      api.error({
        message: "Ajout du projet",
        description: `Erreur : ${error?.message}`,
      });
    },
    update: (cache, { data }) => {
      if (data?.createProject) {
        cache.modify({
          fields: {
            projects: (existingRef: any, { toReference }) => {
              return [toReference(data.createProject), ...existingRef];
            },
          },
        });
      }
    },
  });

  const [updateProject, { loading: loadingUpdate }] = useProjectUpdateMutation({
    onCompleted: () => {
      api.success({
        message: "Modification",
        description: `Projet ${getStatusMasculinMessage(status)} avec succès !`,
      });
      setTimeout(() => {
        navigate("/admin/projects");
      }, 1000);
    },
    onError: (error) => {
      api.error({
        message: "Modification du projet",
        description: `Erreur : ${error?.message}`,
      });
    },
  });

  const updateForm = useCallback(
    (data: ProjectDataFragment | undefined | null) => {
      if (data) {
        form.setFieldsValue({
          title: data.title,
          medias: data.medias.map((m) => m.id),
          type: data.type.id,
          status: data.status,
          description: data.description,
          link: data.link,
        });
        setLogos(data.medias.map((m) => m.id));
      } else {
        form.setFieldsValue({
          title: "",
          medias: [],
          type: undefined,
          status: StatusEnum.Published,
          description: "",
          link: "",
        });
        setLogos([]);
      }
    },
    [form]
  );

  const onFinish = (input: CreateProjectInput) => {
    if (loadingCreate || loadingUpdate) {
      return;
    }
    if (!project) {
      createOffter({
        variables: { input: { ...input, status, medias: logos } },
      });
    } else {
      updateProject({
        variables: {
          input: { ...input, status, medias: logos, id: project.id },
        },
      });
    }
  };

  useEffect(() => {
    updateForm(project);
  }, [project, updateForm]);

  return (
    <>
      {contextHolder}
      <Card style={{ marginTop: 20 }}>
        <Form
          form={form}
          onFinish={onFinish}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
          className="cooperation-form"
        >
          <Form.Item
            name="title"
            label="Titre"
            rules={[{ required: true, message: "Entrez le titre" }]}
          >
            <Input type="filled" size="large" />
          </Form.Item>

          <Row gutter={10} wrap>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                rules={[{ required: true, message: "Séléctionner un type" }]}
                name="type"
                label="Type de projet"
              >
                <Select
                  showSearch
                  size="large"
                  placeholder="Select ..."
                  filterOption={filterOption}
                  optionFilterProp="children"
                  options={types}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item name="link" label="Lien">
                <Input placeholder="https://example.com" size="large" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Logos" name="medias">
            <AppImageInput selected={logos} setSelected={setLogos} />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: "Entrez la description" }]}
          >
            <ReactQuill
              modules={quillModules}
              formats={quillFormats}
              placeholder="description"
              theme="snow"
            />
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};
