import {
  Button,
  Col,
  DatePicker,
  Flex,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  notification,
} from "antd";
import {
  AgreementDataFragment,
  AgreementStatus,
  CreateAgreementInput,
  DictionaryTypeEnum,
  useAgreementUpdateMutation,
  useCreateAgreementMutation,
  useDictionariesQuery,
} from "../../graphql";
import { useCallback, useEffect } from "react";
import { filTerDictionary, filterOption } from "../../utils/build-options";
import { useEstablishment } from "../../hooks/useEstablishment";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import { CheckOutlined, CloseCircleFilled } from "@ant-design/icons";
import { useEnumTranslation } from "../../hooks/useEnumTranslation";
import { cloneDeep } from "lodash";

type Props = {
  agreement?: AgreementDataFragment | undefined | null;
  cooperationId: number;
  onCompleted: (data: AgreementDataFragment) => void;
  cancel: () => void;
  loading?: boolean;
};

type FormAgreementData = Omit<CreateAgreementInput, "endAt" | "startAt"> & {
  endAt: Dayjs;
  startAt: Dayjs;
};

export const AgreementForm = ({
  agreement,
  cooperationId,
  onCompleted,
  cancel,
  loading,
}: Props) => {
  const { t } = useTranslation("index");

  const { AgreementStatusData } = useEnumTranslation();
  const { establishmentsLoading, getEstablishmentOptions } = useEstablishment();

  const [form] = Form.useForm<FormAgreementData>();
  const startAt = Form.useWatch("startAt", form);
  const endAt = Form.useWatch("endAt", form);

  const [api, contextHolder] = notification.useNotification();

  /**Agreement types */
  const { data: dictionaryData, loading: dictionalyLoading } =
    useDictionariesQuery({
      variables: {
        types: [
          DictionaryTypeEnum.AgreementDomain,
          DictionaryTypeEnum.AgreementType,
          DictionaryTypeEnum.AgreementLevel,
        ],
      },
    });

  const getSelectOptions = useCallback(
    (type: DictionaryTypeEnum) =>
      filTerDictionary(type, dictionaryData?.dictionaries || []),
    [dictionaryData]
  );

  const resetFields = useCallback(() => {
    cancel();
    if (agreement) {
      form.setFieldsValue({
        title: agreement.title,
        type: agreement.type?.id,
        cooperation: cooperationId,
        domains: agreement.domains.map((d) => d.id),
        levels: agreement.levels.map((d) => d.id),
        endAt: agreement.endAt ? dayjs(agreement.endAt) : agreement.endAt,
        establishments: agreement.establishments.map((esta) => esta.id),
        startAt: agreement.startAt ? dayjs(agreement.startAt) : undefined,
        status: agreement.status,
        studentsCount: agreement?.studentsCount,
        description: agreement.description,
      });
    } else {
      form.setFieldsValue({
        cooperation: cooperationId,
        domains: [],
        endAt: "",
        startAt: dayjs(),
        establishments: [],
        levels: [],
        status: AgreementStatus.Inactif,
        studentsCount: null,
        title: "",
        type: null as any,
        description: "",
      });
    }
  }, [agreement, cancel, cooperationId, form]);
  useEffect(() => {
    resetFields();
  }, [agreement, resetFields]);

  const [createMutation, { loading: loadingCreation }] =
    useCreateAgreementMutation({
      onCompleted: (data) => {
        onCompleted(cloneDeep(data?.createAgreement));
        api.success({
          message: "Nouvel accord",
          description: "Accord ajouté avec succès !",
        });
        resetFields();
      },
      onError: (error) => {
        api.error({
          message: "Ajout d'accord",
          description: `Erreur : ${error?.message}`,
        });
      },
    });

  const [updateAgreement, { loading: loadingUpdate }] =
    useAgreementUpdateMutation({
      onCompleted(data) {
        onCompleted(cloneDeep(data?.updateAgreement));
        api.success({
          message: "Modification d'accord",
          description: "Accord modifié avec succès !",
        });
      },
      onError(error) {
        api.error({
          message: "Modification d'accord",
          description: `Erreur : ${error?.message}`,
        });
      },
    });

  const onFinish = (vals: FormAgreementData) => {
    const { startAt, endAt, ...res } = vals;
    const input = {
      ...res,
      cooperation: cooperationId,
      startAt: startAt ? startAt.toDate() : null,
      endAt: endAt ? endAt.toDate() : null,
    };
    if (!agreement) {
      createMutation({ variables: { input } });
    } else {
      updateAgreement({ variables: { input: { ...input, id: agreement.id } } });
    }
  };

  return (
    <>
      {contextHolder}
      <Form
        form={form}
        name={`accord-${agreement?.id || 0}`}
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        className="cooperation-form"
      >
        <Form.Item
          name="title"
          label="Titre"
          rules={[{ required: true, message: "Titre requis" }]}
        >
          <Input type="filled" size="large" />
        </Form.Item>

        <Row gutter={10}>
          <Col sm={24} md={12}>
            <Form.Item
              rules={[{ required: true, message: "Type d’accords requis" }]}
              name="type"
              label="Type d’accords"
            >
              <Select
                showSearch
                size="large"
                allowClear
                placeholder="Select ..."
                filterOption={filterOption}
                optionFilterProp="children"
                options={getSelectOptions(DictionaryTypeEnum.AgreementType)}
              />
            </Form.Item>
          </Col>
          <Col sm={24} md={12}>
            <Form.Item
              rules={[{ required: true, message: "Statut de l'accord requis" }]}
              name="status"
              label="Statut de l'accord"
            >
              <Select
                showSearch
                size="large"
                allowClear
                placeholder="Select ..."
                filterOption={filterOption}
                optionFilterProp="children"
                options={AgreementStatusData}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col sm={24} md={12}>
            <Form.Item
              rules={[
                { required: true, message: "Domaines d'expertise requis" },
              ]}
              name="domains"
              label="Domaines d'expertise"
            >
              <Select
                showSearch
                size="large"
                mode="multiple"
                allowClear
                maxTagCount="responsive"
                placeholder="Select ..."
                filterOption={filterOption}
                optionFilterProp="children"
                loading={dictionalyLoading}
                options={getSelectOptions(DictionaryTypeEnum.AgreementDomain)}
              />
            </Form.Item>
          </Col>
          <Col sm={24} md={12}>
            <Form.Item
              name="levels"
              label="Niveau"
              rules={[{ required: true, message: "Niveau requis" }]}
            >
              <Select
                showSearch
                mode="multiple"
                maxTagCount="responsive"
                size="large"
                allowClear
                placeholder="Select ..."
                filterOption={filterOption}
                optionFilterProp="children"
                options={getSelectOptions(DictionaryTypeEnum.AgreementLevel)}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col sm={24} md={12}>
            <Form.Item
              name="establishments"
              label="Université"
              rules={[{ required: true, message: "Université requise" }]}
            >
              <Select
                showSearch
                size="large"
                loading={establishmentsLoading}
                mode="multiple"
                maxTagCount="responsive"
                placeholder="Select ..."
                allowClear
                filterOption={filterOption}
                optionFilterProp="children"
                options={getEstablishmentOptions(DictionaryTypeEnum.FranceCity)}
              />
            </Form.Item>
          </Col>

          <Col sm={24} md={12}>
            <Form.Item
              name="studentsCount"
              label="Nombre d'étudiants d'année courante"
            >
              <InputNumber size="large" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col sm={24} md={12}>
            <Form.Item name="startAt" label="Début">
              <DatePicker
                disabledDate={(d) =>
                  endAt && (d.isSame(endAt) || d.isAfter(endAt))
                }
                format={t("date_short_format")}
              />
            </Form.Item>
          </Col>
          <Col sm={24} md={12}>
            <Form.Item name="endAt" label="Fin">
              <DatePicker
                disabledDate={(d) =>
                  startAt && (d.isSame(startAt) || d.isBefore(startAt))
                }
                format={t("date_short_format")}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="description" label="Description">
          <Input.TextArea rows={5} size="large" />
        </Form.Item>

        <Flex justify="end" gap={10}>
          <Button
            onClick={resetFields}
            size="large"
            icon={<CloseCircleFilled />}
          >
            Annuler
          </Button>
          <Button
            disabled={loadingCreation || loadingUpdate || loading}
            type="primary"
            htmlType="submit"
            size="large"
            icon={<CheckOutlined />}
          >
            Confirmer
          </Button>
        </Flex>
      </Form>
    </>
  );
};
