import { Button, Drawer, List, Space, Tag, Typography } from "antd";
import { useAgreementQuery } from "../../graphql";
import "./AgreementDetails.css";
import { CalendarOutlined, LinkOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { chain } from "lodash";

type Props = {
  id: number;
  close: () => void;
};
export const AgreementDetails = ({ id, close }: Props) => {
  const { t } = useTranslation(["cooperation", "index"]);
  const { loading, data } = useAgreementQuery({
    variables: {
      id,
    },
    skip: !id,
    fetchPolicy: "no-cache",
  });

  return (
    <Drawer
      title={
        <Typography.Title style={{ fontSize: 20 }}>
          {data?.agreement?.title}
        </Typography.Title>
      }
      placement="right"
      width="auto"
      closable={true}
      onClose={close}
      open={!!id}
    >
      <List
        className="agreement-details"
        loading={loading}
        itemLayout="horizontal"
      >
        <List.Item>
          <List.Item.Meta
            title="Type d'accord"
            description={data?.agreement?.type && t(data.agreement.type.value)}
          />
        </List.Item>

        {(data?.agreement.establishments || []).length > 0 ? (
          <List.Item>
            <List.Item.Meta
              title="Université(s)"
              description={data?.agreement.establishments.map((v) => (
                <Button ghost type="text" href={v.link} target="_blank">
                  {v.parent?.name} ({v.town?.value}) <LinkOutlined />
                </Button>
              ))}
            />
          </List.Item>
        ) : (
          ""
        )}

        {(data?.agreement.establishments || []).length > 0 ? (
          <List.Item>
            <List.Item.Meta
              title="Ville"
              description={chain(data?.agreement.establishments)
                .map((e) => e.town?.value || "")
                .uniq()
                .value()
                .join(", ")}
            />
          </List.Item>
        ) : (
          ""
        )}

        {(data?.agreement?.levels || []).length > 0 ? (
          <List.Item>
            <List.Item.Meta
              title="Niveau"
              description={(data?.agreement.levels || [])
                .map((v) => v.value)
                .join(" , ")}
            />
          </List.Item>
        ) : (
          ""
        )}

        <List.Item>
          <List.Item.Meta
            title="Nombre d'étudiants"
            description={data?.agreement?.studentsCount || "Non défini"}
          />
        </List.Item>

        {(data?.agreement?.domains || []).length > 0 ? (
          <List.Item>
            <List.Item.Meta
              title="Disciplines"
              description={data?.agreement.domains
                .map((v) => v.value)
                .join(" , ")}
            />
          </List.Item>
        ) : (
          ""
        )}

        <List.Item>
          <List.Item.Meta
            avatar={<CalendarOutlined color="default" />}
            description={
              <Space>
                {data?.agreement?.startAt && (
                  <Tag style={{ color: "var(--Grey-500)" }} color="default">
                    {dayjs(data.agreement.startAt).format(
                      t("date_short_format", { ns: "index" })
                    )}
                  </Tag>
                )}
                {data?.agreement?.endAt && (
                  <Tag style={{ color: "var(--Grey-500)" }} color="default">
                    {dayjs(data.agreement.endAt).format(
                      t("date_short_format", { ns: "index" })
                    )}
                  </Tag>
                )}
              </Space>
            }
          />
        </List.Item>
      </List>
      <List
        className="agreement-details"
        loading={loading}
        itemLayout="horizontal"
        header={
          <Typography.Title style={{ fontSize: 18 }}>
            Coopération
          </Typography.Title>
        }
      >
        <List.Item>
          <List.Item.Meta
            className="text-justity"
            title={data?.agreement?.cooperation?.title}
            description={data?.agreement?.cooperation?.description}
          />
        </List.Item>

        <List.Item>
          <List.Item.Meta
            title="Université"
            description={
              <Button
                ghost
                type="text"
                href={
                  data?.agreement.cooperation.establishment?.parent?.link ||
                  "#noref"
                }
                target="_blank"
              >
                {`${data?.agreement?.cooperation?.establishment?.parent?.name} (${data?.agreement?.cooperation?.establishment?.town?.value})`}{" "}
                <LinkOutlined />
              </Button>
            }
          />
        </List.Item>

        <List.Item>
          <List.Item.Meta
            title={"Ville"}
            description={
              data?.agreement?.cooperation?.establishment?.town?.value
            }
          />
        </List.Item>
      </List>
    </Drawer>
  );
};
