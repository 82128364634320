import {
  Card,
  Col,
  Flex,
  Form,
  List,
  Row,
  Select,
  Button,
  Tag,
  Badge,
} from "antd";
import Typography from "antd/es/typography/Typography";
import { useTranslation } from "react-i18next";
import { FranceMap } from "./../../components/map/FranceMap";
import { MadagascarMap } from "../../components/map/MadagascarMap";
import {
  AgreementFilterInput,
  DictionaryTypeEnum,
  FilterAgreementsQuery,
  useDictionariesQuery,
  useFilterAgreementsQuery,
} from "../../graphql";
import { useEstablishment } from "../../hooks/useEstablishment";
import { ReactComponent as MadagascarFlag } from "../../assets/madagascar-flag.svg";
import { ReactComponent as FrenchFlag } from "../../assets/french-flag.svg";
import { Option, filterOption } from "../../utils/build-options";
import { useEnumTranslation } from "../../hooks/useEnumTranslation";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AgreementDetails } from "../../components/agreement/AgreementDetails";
import { useResponsive } from "../../hooks/useResponsive";
import { chain, indexOf, isEmpty, isEqual, truncate } from "lodash";
import { agreementTagColor } from "../../utils/status-message";
import { FilterOutlined } from "@ant-design/icons";

const initialValues: AgreementFilterInput = {
  domains: [],
  establishmentsFrance: [],
  establishmentsMada: [],
  status: [],
  townsFrance: [],
  townsMada: [],
  types: [],
  levels: [],
};

export const PublicCooperation = () => {
  const { t } = useTranslation(["cooperation", "index"]);

  const [form] = Form.useForm<AgreementFilterInput>();
  const [formDirty, setFormDirty] = useState(false);
  const madagascarCities = Form.useWatch("townsMada", form);
  const franceCities = Form.useWatch("townsFrance", form);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form]);

  const { AgreementStatusData } = useEnumTranslation();
  const { data: dataDictionnary } = useDictionariesQuery({
    variables: {
      types: [
        DictionaryTypeEnum.AgreementDomain,
        DictionaryTypeEnum.AgreementLevel,
        DictionaryTypeEnum.AgreementType,
        DictionaryTypeEnum.FranceCity,
        DictionaryTypeEnum.MadagascarCity,
      ],
    },
  });

  const dictionaries = useMemo(
    () => dataDictionnary?.dictionaries || [],
    [dataDictionnary?.dictionaries]
  );

  const { getEstablishmentOptions } = useEstablishment();

  const options = useMemo(() => {
    return dictionaries.reduce(
      (acc, cur) => {
        switch (cur.type) {
          case DictionaryTypeEnum.MadagascarCity:
            acc.madagascar.push({
              label: cur.value,
              value: cur.id,
              parentSlug: cur.parent?.slug,
            });
            break;
          case DictionaryTypeEnum.FranceCity:
            acc.france.push({
              label: cur.value,
              value: cur.id,
              parentSlug: cur.parent?.slug,
            });
            break;
          case DictionaryTypeEnum.AgreementDomain:
            acc.domain.push({ label: cur.value, value: cur.id });
            break;
          case DictionaryTypeEnum.AgreementType:
            acc.agreementTypes.push({ label: cur.value, value: cur.id });
            break;
          case DictionaryTypeEnum.AgreementLevel:
            acc.levels.push({ label: cur.value, value: cur.id });
            break;
        }
        return acc;
      },
      {
        madagascar: [] as Option[],
        france: [] as Option[],
        domain: [] as Option[],
        agreementTypes: [] as Option[],
        levels: [] as Option[],
      }
    );
  }, [dictionaries]);

  const madagascarEstablishments = useMemo(() => {
    return getEstablishmentOptions(DictionaryTypeEnum.MadagascarCity).filter(
      (esta: { label: string; value: number; townId: number }) => {
        if (isEmpty(madagascarCities)) return true;
        return indexOf(madagascarCities, esta.townId) >= 0;
      }
    );
  }, [getEstablishmentOptions, madagascarCities]);

  const franceEstablishments = useMemo(() => {
    return getEstablishmentOptions(DictionaryTypeEnum.FranceCity).filter(
      (esta: { label: string; value: number; townId: number }) => {
        if (isEmpty(franceCities)) return true;
        return indexOf(franceCities, esta.townId) >= 0;
      }
    );
  }, [getEstablishmentOptions, franceCities]);

  const [departements, setDepartments] = useState({
    mada: [] as string[],
    france: [] as string[],
  });

  const refreshMap = useCallback(
    (
      data: FilterAgreementsQuery,
      _override: { mada?: Array<string>; france?: Array<string> } | null = null
    ) => {
      const mada = chain(data.filterAgreements)
        .map(
          (agreement) =>
            agreement.cooperation.establishment?.town?.parent?.slug || ""
        )
        .union()
        .value();

      const france = chain(data.filterAgreements)
        .flatMap((agreement) => {
          return agreement.establishments.map(
            (establisment) => establisment?.town?.parent?.slug || ""
          );
        })
        .union()
        .value();
      setDepartments({ mada, france, ..._override });
      setFormDirty(!isEqual(form.getFieldsValue(), initialValues));
    },
    []
  );

  const {
    data: agreementsFiltered,
    refetch,
    loading: filterLoading,
  } = useFilterAgreementsQuery({
    variables: { input: initialValues },
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      refreshMap(data);
    },
    onError(error) {
      console.log(error);
    },
  });

  const handleFormChange = async (_: any, allValues: AgreementFilterInput) => {
    const response = await refetch({ input: allValues });
    refreshMap(response.data);
  };

  const onResetForm = async () => {
    form.setFieldsValue(initialValues);
    setFormDirty(false);
    const response = await refetch({ input: initialValues });
    refreshMap(response.data);
  };

  // Event on click path departement in svg map for Madagascar
  const onSelectMadaMap = async (department: string) => {
    if (filterLoading) return;
    // Clean Madagascar town in form
    form.setFieldValue(
      "townsMada",
      options.madagascar
        .filter((city) => city.parentSlug === department)
        .map((opt) => opt.value)
    );
    //find all towns
    refetch({
      input: {
        ...form.getFieldsValue(),
      },
    });
  };

  const selectFrance = async (department: string) => {
    if (filterLoading) return;
    // Clean France town in form
    form.setFieldValue(
      "townsFrance",
      options.france
        .filter((city) => city.parentSlug === department)
        .map((opt) => opt.value)
    );
    // Remove all madagascar towns when town selected is french
    refetch({
      input: {
        ...form.getFieldsValue(),
      },
    });
  };

  const [details, showDetails] = useState(0);

  const { isDesktop } = useResponsive();

  const ListAccord = useCallback(() => {
    return (
      <List
        loading={filterLoading}
        style={{
          background: "#FFF",
          border: 0,
          maxHeight: isDesktop ? "100vh" : "auto",
          minHeight: "calc(100% - 4em)",
          overflow: "auto",
        }}
        itemLayout="horizontal"
        header={
          <Flex align="center" justify="space-between">
            <div>
              <Badge
                count={agreementsFiltered?.filterAgreements.length || 0}
                offset={[20, 6]}
                color="geekblue"
              >
                <span>Liste d'accords</span>
              </Badge>
            </div>
            {formDirty ? (
              <Button
                onClick={onResetForm}
                type="default"
                danger
                icon={<FilterOutlined />}
              >
                Effacer les filtres
              </Button>
            ) : (
              ""
            )}
          </Flex>
        }
        bordered
        dataSource={agreementsFiltered?.filterAgreements}
        renderItem={(item, index) => (
          <List.Item
            style={{ cursor: "pointer" }}
            onClick={() => showDetails(item.id)}
            key={index}
          >
            <List.Item.Meta
              title={
                <Typography
                  style={{
                    color: "var(--black)",
                    fontSize: 13,
                    fontStyle: "normal",
                    fontWeight: 700,
                  }}
                >
                  {item.title}
                </Typography>
              }
              description={
                <Flex gap={5} justify="space-between" align="center">
                  <span
                    style={{
                      textTransform: "uppercase",
                      fontWeight: "lighter",
                      color: "var(--black)",
                      fontSize: 12,
                    }}
                  >
                    {truncate(item.description, {
                      length: 85,
                      omission: "...",
                    })}
                  </span>
                  <Tag color={agreementTagColor(item.status)}>
                    {t(item.status)}
                  </Tag>
                </Flex>
              }
            />
          </List.Item>
        )}
      />
    );
  }, [
    agreementsFiltered?.filterAgreements,
    t,
    formDirty,
    filterLoading,
    onResetForm,
  ]);

  return (
    <>
      <Form
        form={form}
        onValuesChange={handleFormChange}
        layout="vertical"
        className="public-form"
      >
        <Row gutter={[12, 12]}>
          <Col xs={24} sm={6}>
            <Card
              size="small"
              title={
                <div
                  style={{
                    display: "flex",
                    gap: 10,
                    alignItems: "center",
                  }}
                >
                  <MadagascarFlag />
                  <div>Madagascar</div>
                </div>
              }
            >
              <Form.Item label={t("city")} name="townsMada">
                <Select
                  showSearch
                  allowClear
                  mode="multiple"
                  maxTagCount="responsive"
                  style={{ width: 200 }}
                  placeholder={t("select_placeholder", { ns: "index" })}
                  optionFilterProp="children"
                  filterOption={filterOption}
                  options={dictionaries
                    .filter(
                      (dico) => dico.type === DictionaryTypeEnum.MadagascarCity
                    )
                    .map((dico) => ({
                      label: dico.value,
                      value: dico.id,
                      parentSlug: dico.parent?.slug,
                    }))}
                />
              </Form.Item>

              <Form.Item label={t("establishment")} name="establishmentsMada">
                <Select
                  showSearch
                  allowClear
                  mode="multiple"
                  maxTagCount="responsive"
                  style={{ width: 200 }}
                  placeholder={t("select_placeholder", { ns: "index" })}
                  optionFilterProp="children"
                  filterOption={filterOption}
                  options={madagascarEstablishments}
                />
              </Form.Item>
            </Card>
          </Col>

          <Col xs={24} sm={12}>
            <Card size="small" title={`\r`}>
              <Row gutter={[12, 12]}>
                <Col xs={24} md={12}>
                  <Form.Item label={t("type_accords")} name="types">
                    <Select
                      showSearch
                      allowClear
                      mode="multiple"
                      maxTagCount="responsive"
                      style={{ width: 200 }}
                      placeholder={t("select_placeholder", { ns: "index" })}
                      optionFilterProp="children"
                      filterOption={filterOption}
                      options={options.agreementTypes}
                    />
                  </Form.Item>

                  <Form.Item label={t("status_accord")} name="status">
                    <Select
                      showSearch
                      allowClear
                      mode="multiple"
                      maxTagCount="responsive"
                      style={{ width: 200 }}
                      placeholder={t("select_placeholder", { ns: "index" })}
                      optionFilterProp="children"
                      filterOption={filterOption}
                      options={AgreementStatusData}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item label={t("domain")} name="domains">
                    <Select
                      showSearch
                      allowClear
                      mode="multiple"
                      maxTagCount="responsive"
                      style={{ width: 200 }}
                      placeholder={t("select_placeholder", { ns: "index" })}
                      optionFilterProp="children"
                      filterOption={filterOption}
                      options={options.domain}
                    />
                  </Form.Item>

                  <Form.Item label="Niveau" name="levels">
                    <Select
                      showSearch
                      allowClear
                      filterOption={filterOption}
                      mode="multiple"
                      style={{ width: 200 }}
                      placeholder={t("select_placeholder", { ns: "index" })}
                      optionFilterProp="children"
                      options={options.levels}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col xs={24} sm={6}>
            <Card
              size="small"
              title={
                <div
                  style={{
                    display: "flex",
                    gap: 10,
                    alignItems: "center",
                  }}
                >
                  <FrenchFlag />
                  <div>France</div>
                </div>
              }
            >
              <Form.Item label={t("city")} name="townsFrance">
                <Select
                  showSearch
                  allowClear
                  mode="multiple"
                  maxTagCount="responsive"
                  style={{ width: 200 }}
                  placeholder={t("select_placeholder", { ns: "index" })}
                  optionFilterProp="children"
                  filterOption={filterOption}
                  options={options.france}
                />
              </Form.Item>

              <Form.Item label={t("establishment")} name="establishmentsFrance">
                <Select
                  showSearch
                  allowClear
                  mode="multiple"
                  maxTagCount="responsive"
                  style={{ width: 200 }}
                  placeholder={t("select_placeholder", { ns: "index" })}
                  optionFilterProp="children"
                  filterOption={filterOption}
                  options={franceEstablishments}
                />
              </Form.Item>
            </Card>
          </Col>
        </Row>
      </Form>

      {isDesktop ? (
        <>
          <Row>
            <Col md={24} lg={6}>
              <MadagascarMap
                departements={dictionaries.filter(
                  (d) => d.type === DictionaryTypeEnum.MadagascarCity
                )}
                onSelect={onSelectMadaMap}
                active={departements.mada}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={10}>
              {ListAccord()}
            </Col>
            <Col sm={24} md={24} lg={8}>
              <FranceMap
                departements={dictionaries.filter(
                  (d) => d.type === DictionaryTypeEnum.FranceCity
                )}
                onSelect={selectFrance}
                active={departements.france}
              />
            </Col>
          </Row>
          <div id="map-tooltip" className="hidden"></div>
        </>
      ) : (
        ListAccord()
      )}

      <AgreementDetails id={details} close={() => showDetails(0)} />
    </>
  );
};
