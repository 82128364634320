import {
  Card,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Typography,
  notification,
} from "antd";
import {
  BourseDataFragment,
  CreateBourseInput,
  DictionaryTypeEnum,
  StatusEnum,
  useBourseUpdateMutation,
  useCreateBourseMutation,
  useDictionariesQuery,
} from "../../graphql";
import { useEffect, useMemo, useState } from "react";
import { filTerDictionary, filterOption } from "../../utils/build-options";
import dayjs, { Dayjs } from "dayjs";
import { AppImageInput } from "../common/AppMedia";
import ReactQuill from "react-quill";
import { quillModules, quillFormats } from "../../constants/quill-configs";
import { getStatusMessage } from "../../utils/status-message";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = {
  bourse?: BourseDataFragment | undefined | null;
  form: FormInstance<any>;
  status: StatusEnum;
};

type FormBourseData = Omit<CreateBourseInput, "endAt" | "startAt"> & {
  endAt: Dayjs;
  startAt: Dayjs;
};

export const BourseForm = ({ bourse, status, form }: Props) => {
  const { t } = useTranslation("cooperation");
  const navigate = useNavigate();

  const parent = Form.useWatch("type", form);

  const [api, contextHolder] = notification.useNotification();

  const [logos, setLogos] = useState<number[]>([]);

  /**Cooperations types */
  const { data: dictionaryData, loading: dictionalyLoading } =
    useDictionariesQuery({
      variables: {
        types: [
          DictionaryTypeEnum.Scholarship,
          DictionaryTypeEnum.AgreementDomain,
          DictionaryTypeEnum.AgreementLevel,
        ],
      },
    });

  const options = useMemo(() => {
    const parents = dictionaryData?.dictionaries || [];
    return {
      types: filTerDictionary(
        DictionaryTypeEnum.Scholarship,
        parents.filter((b) => !b.parentId)
      ),
      subTypes: filTerDictionary(
        DictionaryTypeEnum.Scholarship,
        parents.filter((b) => !!b.parentId && b.parentId === parent)
      ),
      disciplines: filTerDictionary(
        DictionaryTypeEnum.AgreementDomain,
        parents
      ),
      levels: filTerDictionary(DictionaryTypeEnum.AgreementLevel, parents),
    };
  }, [dictionaryData, parent]);

  const resetFields = () => {
    if (bourse) {
      form.setFieldsValue({
        title: bourse.title,
        type: bourse.type.id,
        subType: bourse?.subType?.id,
        disciplines: bourse.disciplines.map((d) => d.id),
        levels: bourse.levels.map((d) => d.id),
        endAt: dayjs(bourse.endAt),
        startAt: dayjs(bourse.startAt),
        status: bourse.status,
        description: bourse.description,
        medias: bourse.medias.map((m) => m.id),
      });
      setLogos(bourse.medias.map((m) => m.id));
    } else {
      form.setFieldsValue({
        type: null,
        subType: null,
        disciplines: [],
        endAt: "",
        startAt: dayjs(),
        levels: [],
        status: StatusEnum.Published,
        title: "",
        description: "",
        medias: [],
      });
      setLogos([]);
    }
  };
  useEffect(() => {
    resetFields();
  }, [bourse]);

  const [createMutation, { loading: loadingCreation }] =
    useCreateBourseMutation({
      onCompleted: () => {
        api.success({
          message: "Nouvelle bourse",
          description: `Bourse ajoutée et ${getStatusMessage(
            status
          )} avec succès !`,
        });
        setTimeout(() => {
          navigate("/admin/scholarships");
        }, 500);
      },
      onError: (error) => {
        api.error({
          message: "Ajout de bourse",
          description: `Erreur : ${error?.message}`,
        });
      },
      update: (cache, { data }) => {
        if (data?.createBourse) {
          cache.modify({
            fields: {
              bourses: (existingRef: any, { toReference }) => {
                return [toReference(data.createBourse), ...existingRef];
              },
            },
          });
        }
      },
    });

  const [updateBourse, { loading: loadingUpdate }] = useBourseUpdateMutation({
    onCompleted() {
      api.success({
        message: "Modification de bourse",
        description: "Bourse modifiée avec succès !",
      });
      setTimeout(() => {
        navigate("/admin/scholarships");
      }, 500);
    },
    onError(error) {
      api.error({
        message: "Modification de bourse",
        description: `Erreur : ${error?.message}`,
      });
    },
  });

  const onFinish = (vals: FormBourseData) => {
    const { startAt, endAt, ...res } = vals;
    const input = {
      ...res,
      startAt: startAt ? startAt.toDate() : null,
      endAt: endAt ? endAt.toDate() : null,
      status,
      medias: logos,
    };
    if (!bourse) {
      createMutation({ variables: { input } });
    } else {
      updateBourse({ variables: { input: { ...input, id: bourse.id } } });
    }
  };

  return (
    <>
      {contextHolder}
      <Card style={{ marginTop: 20 }}>
        <Form
          form={form}
          onFinish={onFinish}
          name="validateOnly"
          disabled={loadingCreation || loadingUpdate}
          layout="vertical"
          autoComplete="off"
          className="cooperation-form"
        >
          <Form.Item
            name="title"
            label="Titre"
            rules={[{ required: true, message: "Titre requis" }]}
          >
            <Input type="filled" size="large" />
          </Form.Item>

          <Row gutter={10} wrap>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                rules={[{ required: true, message: "Type de bourse requis" }]}
                name="type"
                label="Type de bourse"
              >
                <Select
                  showSearch
                  size="large"
                  placeholder="Select ..."
                  filterOption={filterOption}
                  optionFilterProp="children"
                  loading={dictionalyLoading}
                  options={options.types}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item name="subType" label="Sous-bourse">
                <Select
                  showSearch
                  size="large"
                  placeholder="Select ..."
                  filterOption={filterOption}
                  loading={dictionalyLoading}
                  optionFilterProp="children"
                  options={options.subTypes}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10} wrap>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                rules={[
                  { required: true, message: "Domaines d'expertise requis" },
                ]}
                name="disciplines"
                label={t("domain")}
              >
                <Select
                  showSearch
                  size="large"
                  mode="multiple"
                  allowClear
                  maxTagCount="responsive"
                  placeholder="Select ..."
                  filterOption={filterOption}
                  loading={dictionalyLoading}
                  optionFilterProp="children"
                  options={options.disciplines}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                rules={[
                  { required: true, message: "Niveaux concernés requis" },
                ]}
                name="levels"
                label="Niveau"
              >
                <Select
                  showSearch
                  size="large"
                  mode="multiple"
                  allowClear
                  maxTagCount="responsive"
                  placeholder="Select ..."
                  filterOption={filterOption}
                  optionFilterProp="children"
                  options={options.levels}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Logos" name="medias">
            <AppImageInput selected={logos} setSelected={setLogos} />
          </Form.Item>

          <div>
            <Typography.Paragraph style={{ margin: 0, marginBottom: 5 }}>
              Période de validité
            </Typography.Paragraph>
            <Row gutter={10} wrap>
              <Col xs={24} sm={24} md={12}>
                <Form.Item name="startAt">
                  <DatePicker />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item name="endAt">
                  <DatePicker />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <Form.Item label="Description" name="description">
            <ReactQuill
              modules={quillModules}
              formats={quillFormats}
              placeholder="description"
              theme="snow"
            />
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};
