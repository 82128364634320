import {
  DownOutlined,
  PlusOutlined,
  RedoOutlined,
  SaveOutlined,
  UpOutlined,
} from "@ant-design/icons";
import {
  Form,
  Row,
  Col,
  Select,
  Button,
  Flex,
  Card,
  Collapse,
  CollapseProps,
  Space,
  Input,
} from "antd";
import { FormInstance } from "antd/es/form";
import {
  AgreementDataFragment,
  CooperationCreateInput,
  CooperationDataFragment,
  DictionaryTypeEnum,
  StatusEnum,
  useDictionariesQuery,
} from "../../graphql";
import { filTerDictionary, filterOption } from "../../utils/build-options";
import { useCallback, useEffect, useState } from "react";
import { useEstablishment } from "../../hooks/useEstablishment";
import { AgreementForm } from "../agreement/AgreementForm";
import { useCooperation } from "../../hooks/cooperation/useCooperation";
import { useTranslation } from "react-i18next";
import { useRemoveAgreement } from "../../hooks/agreement/useRemoveAgreement";

type Props = {
  form: FormInstance<CooperationCreateInput>;
  onSaved: (data: CooperationDataFragment | null | undefined) => void;
  cooperation: CooperationDataFragment | null | undefined;
  status: StatusEnum;
  submit: (status: StatusEnum) => void;
};

export const CooperationForm = ({
  form,
  onSaved,
  status,
  cooperation,
  submit,
}: Props) => {
  const {
    createCooperation,
    updateCooperation,
    contextHolder,
    contextHolderModal,
    loadingCreation,
    loadingUpdate,
    api,
    modal,
  } = useCooperation({ onSaved, status });

  const { removeAgreementExtra, loading: loadingRemoveAgreement } =
    useRemoveAgreement({
      api,
      modal,
      onDone: (id) => {
        console.log(id);
        if (cooperation) {
          cooperation.agreements = cooperation.agreements.filter(
            (ag) => ag.id !== id
          );
        }
      },
    });

  const { t } = useTranslation(["cooperation", "index"]);
  /**Cooperations types */
  const { data: dictionaryData, loading: dictionalyLoading } =
    useDictionariesQuery({
      variables: {
        types: [
          DictionaryTypeEnum.CooperationType,
          DictionaryTypeEnum.MadagascarCity,
          DictionaryTypeEnum.CooperationDiscipline,
        ],
      },
    });

  const getSelectOptions = useCallback(
    (type: DictionaryTypeEnum) =>
      filTerDictionary(type, dictionaryData?.dictionaries || []),
    [dictionaryData]
  );

  /***Establishements */
  const { establishmentsLoading, getEstablishmentOptions } = useEstablishment();

  const [expand, setExpand] = useState(true);

  const updateForm = useCallback(
    (data: CooperationDataFragment | undefined | null) => {
      if (data) {
        form.setFieldsValue({
          title: data.title,
          types: data.types.map(({ id }) => id),
          disciplines: data.disciplines.map(({ id }) => id),
          establishment: data?.establishment?.id,
          link: data.link || "",
          status: data.status,
          description: data.description,
        });
      } else {
        form.setFieldsValue({
          title: "",
          types: [],
          disciplines: [],
          establishment: null as any,
          link: "",
          status: StatusEnum.Published,
          description: "",
        });
      }
    },
    [form]
  );

  const onFinish = (input: CooperationCreateInput) => {
    if (loadingCreation && loadingUpdate) {
      return;
    }
    if (!cooperation) {
      createCooperation({ variables: { input: { ...input, status } } });
    } else {
      updateCooperation({
        variables: { input: { ...input, status, id: cooperation.id } },
      });
    }
  };

  useEffect(() => {
    updateForm(cooperation);
  }, [cooperation, updateForm]);

  const onAgreementAdded = useCallback(
    (agreement: AgreementDataFragment) => {
      if (cooperation) {
        const findIndex = cooperation.agreements.findIndex(
          (ag) => ag.id === agreement.id
        );
        if (findIndex >= 0) {
          cooperation.agreements[findIndex] = agreement;
        } else {
          cooperation.agreements.push(agreement);
        }
        //Refresh data
        onSaved({ ...cooperation });
      }
    },
    [cooperation, onSaved]
  );

  const items = useCallback(() => {
    const items: CollapseProps["items"] = [];
    if (cooperation) {
      items.push({
        key: "0",
        label: (
          <Space>
            <PlusOutlined />
            {t("new-agreement")}
          </Space>
        ),
        children: (
          <AgreementForm
            cancel={() => {}}
            onCompleted={onAgreementAdded}
            cooperationId={cooperation.id}
            loading={loadingRemoveAgreement}
          />
        ),
      });
      const agreements = cooperation.agreements.map((ag) => ({
        key: ag.id,
        label: ag.title,
        children: (
          <AgreementForm
            cancel={() => {}}
            onCompleted={onAgreementAdded}
            cooperationId={cooperation.id}
            agreement={ag}
          />
        ),
        extra: removeAgreementExtra(ag.id),
      }));
      items.push(...agreements);
    }
    return items;
  }, [
    cooperation,
    onAgreementAdded,
    removeAgreementExtra,
    loadingRemoveAgreement,
    t,
  ]);

  return (
    <>
      {contextHolder}
      {contextHolderModal}
      <Card
        title={
          <Flex gap={10} justify="end" style={{ width: "100%" }}>
            <Button
              onClick={() => form.resetFields()}
              type="text"
              icon={<RedoOutlined />}
            />
            <Button
              onClick={() => setExpand(!expand)}
              type="text"
              icon={expand ? <DownOutlined /> : <UpOutlined />}
            />
          </Flex>
        }
        style={{ marginTop: 20 }}
        actions={
          cooperation
            ? [
                <h3
                  style={{
                    color: "var(--blue-france-500)",
                    textAlign: "left",
                    paddingLeft: 20,
                    marginTop: 0,
                    marginBottom: 5,
                  }}
                >
                  Accords de la coopération
                </h3>,
              ]
            : []
        }
      >
        {expand && (
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            onFinish={onFinish}
            className="cooperation-form"
          >
            <Form.Item
              name="title"
              label="Titre"
              rules={[{ required: true, message: "Titre requis" }]}
            >
              <Input type="filled" size="large" />
            </Form.Item>
            <Row gutter={10}>
              <Col sm={24} md={12}>
                <Form.Item
                  rules={[{ required: true, message: t("type-required") }]}
                  name="types"
                  label="Type de coopération"
                >
                  <Select
                    loading={dictionalyLoading}
                    mode="multiple"
                    maxTagCount="responsive"
                    showSearch
                    allowClear
                    size="large"
                    placeholder="Select ..."
                    filterOption={filterOption}
                    optionFilterProp="children"
                    options={getSelectOptions(
                      DictionaryTypeEnum.CooperationType
                    )}
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={12}>
                <Form.Item
                  rules={[{ required: true, message: "Disciplines requises" }]}
                  name="disciplines"
                  label="Disciplines concernées"
                >
                  <Select
                    loading={dictionalyLoading}
                    showSearch
                    maxTagCount="responsive"
                    size="large"
                    allowClear
                    mode="multiple"
                    placeholder="Select ..."
                    filterOption={filterOption}
                    optionFilterProp="children"
                    options={getSelectOptions(
                      DictionaryTypeEnum.CooperationDiscipline
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col sm={24} md={12}>
                <Form.Item
                  name="establishment"
                  label="Université"
                  rules={[{ required: true, message: "Université requise" }]}
                >
                  <Select
                    showSearch
                    size="large"
                    placeholder="Select ..."
                    allowClear
                    filterOption={filterOption}
                    optionFilterProp="children"
                    options={getEstablishmentOptions(
                      DictionaryTypeEnum.MadagascarCity
                    )}
                    loading={establishmentsLoading}
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={12}>
                <Form.Item name="link" label="Lien">
                  <Input placeholder="https://example.com" size="large" />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name="description" label="Description">
              <Input.TextArea rows={5} size="large" />
            </Form.Item>

            <Flex justify="center">
              <Button
                onClick={() => submit(StatusEnum.Published)}
                type="primary"
                size="large"
                icon={<SaveOutlined />}
              >
                {t("save", { ns: "index" })}
              </Button>
            </Flex>
          </Form>
        )}
      </Card>

      <Collapse
        style={{ border: 0, padding: 0, marginTop: 20 }}
        className="cooperation-collapse"
        expandIconPosition="end"
        accordion
        items={items()}
      />
    </>
  );
};
