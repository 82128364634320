import { useRemoveAgreementMutation } from "../../graphql";
import { DeleteFilled, ExclamationCircleFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { NotificationInstance } from "antd/es/notification/interface";
import { HookAPI } from "antd/es/modal/useModal";
import { Button } from "antd";

type RemoveAgreementProps = {
  api: NotificationInstance;
  modal: HookAPI;
  onDone: (id: number) => void;
};
export const useRemoveAgreement = ({
  api,
  modal,
  onDone,
}: RemoveAgreementProps) => {
  const { t } = useTranslation("index");

  const [removeMutation, { loading }] = useRemoveAgreementMutation({
    onCompleted: (data) => {
      if (data?.removeAgreement) {
        api.success({
          message: "Suppression de l'accord",
          description: `L'accord supprimé avec succès !`,
        });
        onDone(data.removeAgreement);
      } else {
        api.error({
          message: "Suppression de l'accord",
          description: `Une erreur est survenue lors de la suppression! Réessayez plutard`,
        });
      }
    },
    onError(err) {
      api.error({
        message: "Suppression de l'accord",
        description: `Erreur : ${err?.message}`,
      });
    },
    update: (cache, { data }) => {
      if (data?.removeAgreement) {
        cache.modify({
          fields: {
            cooperation: (existingRef: any, { readField }) => {
              const agreements: readonly any[] =
                readField("agreements", existingRef) || [];
              return {
                ...existingRef,
                agreements: agreements.filter(
                  (agr: any) => readField("id", agr) !== data.removeAgreement
                ),
              };
            },
          },
        });
      }
    },
  });

  const removeAgreementExtra = (id: number) => (
    <Button
      style={{ marginRight: 15 }}
      size="small"
      danger
      onClick={(event) => {
        modal.confirm({
          title: "Etes-vous sûr de vouloir supprimer ?",
          icon: <ExclamationCircleFilled />,
          content: "Cet accord sera supprimé définitivement.",
          okText: t("yes"),
          okType: "danger",
          centered: true,
          okButtonProps: {
            danger: true,
          },
          cancelText: t("no"),
          onOk: () => removeMutation({ variables: { id } }),
        });
        event.stopPropagation();
      }}
      icon={<DeleteFilled />}
    />
  );

  return {
    removeAgreementExtra,
    loading,
  };
};
